@font-face {
  font-family: 'bentonsansregular';
  src: url(/assets/fonts/bentonsans_medium-webfont.9ef3018b2ab8e0940b7ef834da403faa.woff2) format("woff2"), url(/assets/fonts/bentonsans_medium-webfont.3a9fc03d851d605f054b49fd0e522e56.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

.cookie-box {
  font-size: 12px;
  font-family: 'bentonsansregular';
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #ffffff;
  padding: 15px 0;
  z-index: 99999;
  background: #005898;
}

.cookie-box.cookie-hidden {
  display: none;
}

.cookie-box .cookie-button {
  display: inline-block;
  background: #ffffff;
  background-size: 18px;
  border-radius: 5px;
  border: none;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
  letter-spacing: 0px;
  font-size: 12px;
  padding: 10px 15px 10px 15px;
  outline: none;
  cursor: pointer;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  color: #005898;
}

.cookie-box .cookie-button:hover {
  background: #005898;
  background-size: 18px;
  color: #ffffff;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.cookie-box .cookie-button svg {
  margin-right: 5px;
  vertical-align: top;
  fill: #005898;
}

.cookie-box .cookie-link {
  color: black;
  margin: 0 15px;
}

.cookie-box .cookie-link:hover {
  color: #ffffff;
}

#cookie-popup.cookie-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  -webkit-transition: opacity 500ms;
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
  z-index: 99999;
}

#cookie-popup.cookie-overlay:target {
  visibility: visible;
  opacity: 1;
}

#cookie-popup.cookie-overlay:target .cookie-popup {
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

#cookie-popup.cookie-overlay .cookie-popup {
  font-size: 12px;
  font-family: 'bentonsansregular';
  background: #005898;
  position: absolute;
  padding: 30px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
  color: #ffffff;
  border-radius: 5px;
  width: 100%;
  top: 0;
  left: 0;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

@media screen and (min-width: 768px) {
  #cookie-popup.cookie-overlay .cookie-popup {
    position: relative;
    width: 75%;
    left: 50%;
    top: 30px;
    -webkit-transform: translateX(-50%);
    -o-transition: translateX(-50%);
    -moz-transition: translateX(-50%);
    transform: translateX(-50%);
  }
}

@media screen and (min-width: 992px) {
  #cookie-popup.cookie-overlay .cookie-popup {
    width: 75%;
  }
}

@media screen and (min-width: 1200px) {
  #cookie-popup.cookie-overlay .cookie-popup {
    width: 50%;
  }
}

@media screen and (min-width: 1400px) {
  #cookie-popup.cookie-overlay .cookie-popup {
    width: 40%;
  }
}

#cookie-popup.cookie-overlay .cookie-popup .cookie-close {
  position: absolute;
  top: 5px;
  right: 15px;
  -webkit-transition: all 200ms;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #ffffff;
}

#cookie-popup.cookie-overlay .cookie-popup .cookie-close:hover {
  color: black;
}

#cookie-popup.cookie-overlay .cookie-popup .cookie-content {
  font-size: 14px;
  height: 400px;
  overflow: scroll;
}

@media screen and (min-width: 768px) {
  #cookie-popup.cookie-overlay .cookie-popup .cookie-content {
    height: 80vh;
    overflow: auto;
  }
}

@media screen and (min-width: 992px) {
  #cookie-popup.cookie-overlay .cookie-popup .cookie-content {
    height: 70vh;
    overflow: auto;
  }
}

@media screen and (min-width: 1200px) {
  #cookie-popup.cookie-overlay .cookie-popup .cookie-content {
    height: 57vh;
    overflow: auto;
  }
}

#cookie-popup.cookie-overlay .cookie-popup .cookie-content .cookie-button {
  display: inline-block;
  background: #ffffff;
  background-size: 18px;
  border-radius: 5px;
  border: none;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
  letter-spacing: 0px;
  font-size: 12px;
  padding: 10px 15px 10px 15px;
  outline: none;
  cursor: pointer;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  color: #005898;
}

#cookie-popup.cookie-overlay .cookie-popup .cookie-content .cookie-button:hover {
  background: #005898;
  background-size: 18px;
  color: #ffffff;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

#cookie-popup.cookie-overlay .cookie-popup .cookie-content .cookie-button svg {
  margin-right: 5px;
  vertical-align: top;
  fill: #005898;
}

#cookie-popup.cookie-overlay .cookie-popup .cookie-content ul {
  margin: 0;
  margin-left: 15px;
  padding: 0;
}

#cookie-popup.cookie-overlay .cookie-popup .cookie-content .cookie-slider {
  width: 70px;
  height: 30px;
  margin: 0 auto;
  margin-top: 15px;
  border: 2px solid white;
  border-radius: 50px;
  position: relative;
  -webkit-transition: all .4s ease;
  -moz-transition: all .4s ease;
  -o-transition: all .4s ease;
  -ms-transition: all .4s ease;
}

#cookie-popup.cookie-overlay .cookie-popup .cookie-content .cookie-slider.active {
  background-color: black;
  -webkit-transition: all .4s ease;
  -moz-transition: all .4s ease;
  -o-transition: all .4s ease;
  -ms-transition: all .4s ease;
}

#cookie-popup.cookie-overlay .cookie-popup .cookie-content .cookie-slider label {
  display: block;
  width: 30px;
  height: 20px;
  border-radius: 50px;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  cursor: pointer;
  position: absolute;
  top: 3px;
  left: 3px;
  z-index: 1;
  background-color: #ffffff;
}

#cookie-popup.cookie-overlay .cookie-popup .cookie-content .cookie-slider input[type=checkbox] label {
  background-color: #fff;
}

#cookie-popup.cookie-overlay .cookie-popup .cookie-content .cookie-slider input[type=checkbox]:checked + label {
  left: 33px;
  background-color: #fff;
}

#cookie-popup.cookie-overlay .cookie-popup .cookie-content .cookie-slider:before {
  /* content: 'AAN'; */
  color: #fff;
  position: absolute;
  top: 4px;
  left: 6px;
  z-index: 0;
  font-weight: bold;
  font-size: 10px;
}

#cookie-popup.cookie-overlay .cookie-popup .cookie-content .cookie-slider:after {
  /* content: 'UIT'; */
  color: #fff;
  position: absolute;
  right: 10px;
  top: 6px;
  z-index: 0;
  font-weight: bold;
  font-size: 10px;
}

#cookie-popup.cookie-overlay .cookie-popup .cookie-content .cookie-checkbox {
  visibility: hidden;
}

#cookie-popup.cookie-overlay .cookie-popup .cookie-content .cookie-disable {
  position: relative;
  z-index: 2;
  height: 30px;
  margin-top: -30px;
}

#cookie-popup.cookie-overlay .cookie-popup .cookie-content .cookie-on {
  position: absolute;
  left: 10px;
  top: 4px;
  font-size: 10px;
}

#cookie-popup.cookie-overlay .cookie-popup .cookie-content .cookie-off {
  position: absolute;
  right: 10px;
  top: 4px;
  font-size: 10px;
}

#cookie-popup.cookie-overlay .cookie-popup .cookie-content .cookie-block {
  text-align: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

@media screen and (min-width: 768px) {
  #cookie-popup.cookie-overlay .cookie-popup .cookie-content .cookie-block {
    float: left;
    width: 33%;
  }
}

#cookie-popup.cookie-overlay .cookie-popup .cookie-content .cookie-block .cookie-img {
  width: 100px;
  margin: 0 auto;
}

#cookie-popup.cookie-overlay .cookie-popup .cookie-content .cookie-block .cookie-img img {
  width: 100%;
  margin-top: 30px;
}

#cookie-popup.cookie-overlay .cookie-popup .cookie-content #cookie-active {
  opacity: 0.5;
}

#cookie-popup.cookie-overlay .cookie-popup .cookie-content .cookie-privacy {
  color: #ffffff;
}

#cookie-popup.cookie-overlay .cookie-popup .cookie-content .cookie-privacy:hover {
  color: black;
}

#cookie-popup.cookie-overlay .cookie-popup .cookie-footer {
  width: 100%;
  float: left;
  text-align: center;
  margin-top: 30px;
}

#cookie-popup.cookie-overlay .cookie-popup h2 {
  margin-top: 0;
  color: #ffffff;
}

#cookie-popup.cookie-overlay .cookie-popup h3 {
  font-size: 1em;
  color: #ffffff;
  margin-top: 15px;
}