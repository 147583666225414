/* Document */

html {
  width: 100%;
}

body {
  width: 100%;
  font-family: 'Noto Sans', sans-serif;
  font-size: 15px;
  line-height: 1.7;
  color: #696d6e;
}

/* Heading */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  color: #5d6061;
  line-height: 1.2;
}

h1 {
  margin: 0 0 25px;
  font-size: 30px;
  font-weight: 400;
  color: #0fab30;
}

h2 {
  margin: 5px 0 20px;
  font-size: 20px;
}

h3 {
  margin: 5px 0 10px;
  font-size: 17px;
}

/* Paragraph */

p {
  margin: 10px 0 25px;
}

/* Hyperlink */

a {
  color: #20b8d6;
  text-decoration: underline;
}

a:hover,
a:focus {
  color: #119bb6;
  text-decoration: none;
}

/* Address */

address {
  line-height: 1.7;
}

/* List */

ul,
ol {
  margin: 0 0 25px;
}

/* Lines */

hr {
  position: relative;
  margin: 40px 0;
  border-top-color: #edf0f1;
}

hr.space-top-small {
  margin-top: 10px;
}

hr.space-bottom-small {
  margin-bottom: 10px;
}

hr.space-top-collapse {
  margin-top: 0;
}

hr.space-bottom-collapse {
  margin-bottom: 0;
}

/* Text color */

.text-primary {
  color: #0fab30;
}

.text-light {
  color: #929697;
}

.text-dark {
  color: #393d3e;
}

.text-white {
  color: #fff;
}

.text-danger {
  color: #e41c33;
}

.text-default {
  color: #696d6e;
}

/* Text size */

.small {
  font-size: 90%;
}

.smaller {
  font-size: 80%;
}

.smallest {
  font-size: 70%;
}

/* Background color */

.bg-primary {
  background-color: #0fab30;
}

.bg-white {
  background-color: #fff;
}

.bg-light {
  background-color: #f0f1f7;
}

.bg-danger {
  background-color: #e41c33;
}

/* Button */

.btn {
  padding: 10px;
  height: 40px;
  font-size: 13px;
  font-weight: 600;
  text-decoration: none;
  line-height: normal;
  border-radius: 2px;
  border: 1px solid transparent;
}

.btn:hover,
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
  border-color: transparent;
}

.btn.btn-primary {
  color: #fff;
  background: #20b8d6;
}

.btn.btn-primary:hover {
  color: #fff;
  background: #119bb6;
}

.btn.btn-white {
  color: #393d3e;
  background-color: #fff;
}

.btn.btn-white:hover {
  color: #119bb6;
  background-color: #fff;
}

.btn [class*="icon-arrow-"] {
  font-size: 14px;
  vertical-align: -1px;
  line-height: 0;
  display: inline-block;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: -webkit-transform .15s ease-in-out;
  transition: -webkit-transform .15s ease-in-out;
  transition: transform .15s ease-in-out;
  transition: transform .15s ease-in-out, -webkit-transform .15s ease-in-out;
}

.btn:hover [class^="icon-arrow-"][class$="-left"] {
  -webkit-transform: translateX(-3px);
  transform: translateX(-3px);
}

.btn:hover [class^="icon-arrow-"][class$="-right"] {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

/* Forms */

.control-label,
.form-horizontal .control-label {
  padding-top: 11px;
  padding-bottom: 5px;
  font-size: 14px;
  font-weight: normal;
  text-align: left;
  line-height: normal;
}

.form-control {
  height: 42px;
  padding: 10px 8px;
  color: #696d6e;
  border: 1px solid #dcd7d8;
  border-radius: 2px;
  box-shadow: none;
}

.form-control.noborder {
  border-color: transparent;
}

textarea.form-control {
  height: 160px;
  resize: vertical;
}

.form-control.error {
  border-color: #da4e32;
}

.form-control:focus {
  border-color: #76a6ec;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.form-group {
  margin-bottom: 10px;
}

.form-msg {
  padding-top: 5px;
  font-size: 12px;
  white-space: nowrap;
}

.form-msg ul {
  margin: 0;
  padding: 0;
  list-style-position: inside;
}

.form-msg li {
  line-height: normal;
}

.checkbox-inline,
.radio-inline {
  padding-top: 10px;
}

/* Table */

.table > tbody > tr > td,
.table > tbody > tr > th {
  padding: 10px;
  border-color: #eee;
  border-bottom: 1px solid #ebecf1;
}

.table > tfoot > tr > td,
.table > tfoot > tr > th {
  padding: 10px;
  border-color: #eee;
  border-bottom: 1px solid #ebecf1;
}

.table > thead > tr > td,
.table > thead > tr > th {
  padding: 10px;
  border-color: #eee;
  border-bottom: 1px solid #ebecf1;
}

.table > tbody > tr > th {
  font-weight: 400;
}

.table td:first-child,
.table th:first-child {
  padding-left: 0;
}

.table td:last-child,
.table th:last-child {
  padding-right: 0;
}

.table > thead > tr > th {
  border-bottom-width: 1px;
}

/* Container */

.container,
.container-fluid {
  padding-left: 20px;
  padding-right: 20px;
}

/* Content width */

.content-width-large {
  max-width: 1366px;
}

.content-width-medium {
  max-width: 1024px;
}

.content-width-small {
  max-width: 768px;
}

/* Content padding */

.content-padding {
  padding-top: 30px;
  padding-bottom: 30px;
}

.content-padding > h1:last-child,
.content-padding > p:last-child,
.content-padding > ul:last-child,
.content-padding > ol:last-child {
  margin-bottom: 0;
}

/* Spacer */

.spacer {
  height: 30px;
  clear: both;
}

.spacer-large {
  height: 60px;
  clear: both;
}

.spacer-small {
  height: 15px;
  clear: both;
}

.spacer-xsmall {
  height: 5px;
  clear: both;
}

/* Row */

.row {
  margin-left: -20px;
  margin-right: -20px;
}

.row.gutter-small {
  margin-left: -10px;
  margin-right: -10px;
}

.row.gutter-xsmall {
  margin-left: -5px;
  margin-right: -5px;
}

.row.gutter-collapse {
  margin-left: 0;
  margin-right: 0;
}

.row > [class*="col-"] {
  padding-left: 20px;
  padding-right: 20px;
}

.row.gutter-small > [class*="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}

.row.gutter-xsmall > [class*="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

.row.gutter-collapse > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

/* Columns */

/* Block */

.block {
  padding: 20px;
}

/* Required */

.required {
  margin: 0 -100% 0 3px;
  width: 4px;
  height: 4px;
  text-indent: -1234em;
  vertical-align: 1px;
  border-radius: 50%;
  background-color: #da4e32;
  display: inline-block;
}

/* Object fit */

.object-fit-cover {
  -o-object-fit: cover;
     object-fit: cover;
}

.object-fit-contain {
  -o-object-fit: contain;
     object-fit: contain;
}

/* Fit */

.fit {
  position: relative;
  width: 100%;
  height: 100%;
  display: inline-block;
  overflow: hidden;
}

.fit > [class*="object-fit-"] {
  position: absolute;
  left: -100%;
  right: -100%;
  top: -100%;
  bottom: -100%;
  margin: auto;
}

.fit.landscape > .object-fit-cover {
  width: 100%;
  max-width: 100%;
  height: auto;
  max-height: none;
}

.fit.landscape > .object-fit-contain {
  width: auto;
  max-width: none;
  height: 100%;
  max-height: none;
}

.fit.portrait > .object-fit-cover {
  width: auto;
  max-width: none;
  height: 100%;
  max-height: 100%;
}

.fit.portrait > .object-fit-contain {
  width: 100%;
  max-width: 100%;
  height: auto;
  max-height: none;
}

/* Panel */

.panel {
  position: fixed;
  top: 0;
  left: 0;
  margin-top: -60px;
  width: 100%;
  height: 60px;
  line-height: 60px;
  z-index: 1000;
  -webkit-transition: margin .3s;
  transition: margin .3s;
}

.panel.show {
  margin-top: 0;
}

.panel.show + .panel.show {
  margin-top: 60px;
}

.panel .panel-inner {
  position: relative;
  height: 100%;
}

.panel h1 {
  margin: 0;
  font-size: 18px;
  line-height: 60px;
  white-space: nowrap;
}

.panel .dismiss {
  margin-top: 11px;
  float: right;
}

/* Meta */

.meta {
  position: relative;
  top: -20px;
}

/* Social items */

.social-items {
  font-size: 0;
}

.social-items .social {
  margin: 0 1px;
}

/* Social */

.social {
  width: 26px;
  height: 26px;
  font-size: 13px;
  color: #fff;
  line-height: 26px;
  text-align: center;
  border-radius: 50%;
  background-color: #2b0699;
  display: inline-block;
}

.social:hover {
  color: #fff;
  background-color: #ee2a2e;
}

/* Share */

.share {
  margin-right: 5px;
  font-size: 0;
  vertical-align: middle;
  display: inline-block;
}

/* Usp */

.usp {
  margin: 0;
  padding: 0;
  list-style: none;
}

.usp li {
  margin: 0 0 10px;
  padding: 0 0 0 24px;
  line-height: normal;
}

.usp li:before {
  margin: 1px 0 0 -24px;
  width: 20px;
  height: 20px;
  content: '\E801';
  font-family: 'icons';
  color: #fff;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  background-color: #ddd;
  display: block;
  float: left;
}

/* Logo */

.logo {
  position: relative;
  width: 100px;
  float: left;
  display: block;
}

.logo img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

/* Nav */

.nav ul {
  margin: 0;
  padding: 0;
}

.nav li {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* Menu */

.menu {
  position: fixed;
  top: 0;
  left: 0;
  margin-left: -1234em;
  width: 100%;
  height: 100%;
  z-index: 1000;
  -webkit-transition: margin 0s .3s;
  transition: margin 0s .3s;
}

.menu-open .menu {
  margin-left: 0;
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}

.menu:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background-color: #000;
  display: block;
  display: none\9;
  -webkit-transition: opacity .3s;
  transition: opacity .3s;
  opacity: 0;
  z-index: 1;
}

.menu-open .menu:before {
  opacity: .8;
}

.menu .menu-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin-left: -100%;
  width: 75%;
  background-color: #fff;
  -webkit-transition: margin .3s ease-in-out;
  transition: margin .3s ease-in-out;
  z-index: 2;
  overflow: hidden;
  overflow-y: auto;
}

.menu-open .menu .menu-container {
  margin-left: 0;
}

.menu ul {
  position: relative;
  margin-bottom: 30px;
  z-index: 2;
}

.menu li {
  position: relative;
  border-top: 1px solid #e7eaee;
}

.menu a {
  position: relative;
  padding: 5px 20px;
  font-size: 15px;
  color: #2d3e51;
  text-decoration: none;
  display: block;
}

.menu li.hover > a,
.menu li:hover > a,
.menu li.active > a {
  color: #0fab30;
}

.menu a button {
  position: absolute;
  top: 4px;
  margin-left: -4px;
  padding: 0;
  width: 30px;
  height: 30px;
  font-size: 16px;
  border: none;
  background-color: transparent;
  display: inline-block;
}

.menu li.hover > a button,
.menu li.active > a button {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.menu ul ul {
  margin-bottom: 0;
  padding: 20px 0;
  background-color: #0fab30;
  display: none;
}

.menu li.hover > ul,
.menu li.active > ul {
  display: block;
}

.menu ul ul li {
  border-top-color: #0b9e2a;
}

.menu ul ul li:first-child {
  border-top: none;
}

.menu ul ul a {
  font-size: 13px;
  color: #023a0d;
}

.menu ul ul li.hover > a,
.menu ul ul li.active > a {
  color: #fff;
}

.menu ul ul a button {
  top: 2px;
  font-size: 14px;
}

.menu ul ul ul {
  background-color: #1bbd3d;
}

.menu .menu-toggler {
  margin: 20px 0 40px 20px;
  width: 40px;
  display: block;
  z-index: 2;
}

/* Menu footer */

.menu-footer {
  position: relative;
  top: -1px;
  margin: 0 20px;
  vertical-align: middle;
  display: inline-block;
}

.menu-footer li {
  margin: 0 5px;
  float: left;
}

/* Menu sub */

.menu-sub {
  background-color: #0fab30;
}

.menu-sub .menu-sub-title {
  color: #fff;
}

.menu-sub > ul {
  margin: 0 -20px;
}

.menu-sub li {
  border-top: 1px solid #0b9e2a;
}

.menu-sub li:first-child {
  border-top: none;
}

.menu-sub a {
  padding: 5px 20px;
  font-size: 13px;
  color: #023a0d;
  text-decoration: none;
  display: block;
}

.menu-sub a:hover {
  color: #fff;
}

.menu-sub li.active > a {
  color: #fff;
}

.menu-sub ul ul a {
  padding: 3px 40px;
}

/* Menu toggler */

.menu-toggler {
  position: relative;
  color: #fff;
  background-color: #0fab30;
  display: inline-block;
}

.menu-toggler:hover,
.menu-toggler:focus {
  color: #fff;
  background-color: #0fab30;
}

.menu-toggler .menu-toggler-symbol {
  margin-right: 3px;
  vertical-align: -3px;
  display: inline-block;
}

.menu-toggler .menu-toggler-symbol span {
  margin: 3px auto;
  width: 15px;
  height: 1px;
  text-indent: -1234em;
  background-color: #fff;
  display: block;
  overflow: hidden;
  -webkit-transition: height .2s, opacity .2s, background-color .2s, -webkit-transform .2s;
  transition: height .2s, opacity .2s, background-color .2s, -webkit-transform .2s;
  transition: height .2s, opacity .2s, background-color .2s, transform .2s;
  transition: height .2s, opacity .2s, background-color .2s, transform .2s, -webkit-transform .2s;
}

.menu-toggler .menu-toggler-symbol span:first-child {
  margin-top: 2px;
}

.menu-open .menu-toggler .menu-toggler-symbol span {
  height: 2px;
}

.menu-open .menu-toggler .menu-toggler-symbol span:first-child {
  -webkit-transform: rotate(45deg) translate(4px, 4px);
  transform: rotate(45deg) translate(4px, 4px);
}

.menu-open .menu-toggler .menu-toggler-symbol span:first-child + span {
  opacity: 0;
}

.menu-open .menu-toggler .menu-toggler-symbol span:last-child {
  -webkit-transform: rotate(-45deg) translate(3px, -3px);
  transform: rotate(-45deg) translate(3px, -3px);
}

/* Breadcrumb */

.breadcrumb ul {
  white-space: nowrap;
  overflow: hidden;
  overflow-x: auto;
}

.breadcrumb li {
  height: 50px;
  font-size: 12px;
  color: #686a6c;
  line-height: 50px;
  display: inline-block;
}

.breadcrumb li:before {
  margin-right: 2px;
  font-family: 'icons';
  font-size: 12px;
  color: #949ea5;
  content: '\E800';
  vertical-align: -1px;
  display: inline-block;
}

.breadcrumb li:first-child:before {
  display: none;
}

.breadcrumb a {
  height: 100%;
  color: #949ea5;
  display: inline-block;
}

.breadcrumb a:hover {
  text-decoration: none;
}

/* Visual */

.visual {
  position: relative;
  height: 200px;
}

.visual.visual-small {
  height: 100px;
}

.visual .visual-image {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.visual .visual-image img {
  width: 100%;
  height: 100%;
}

/* Slider */

.slider {
  position: relative;
}

.slider .slider-slides {
  position: relative;
  height: 100%;
  z-index: 1;
  overflow: hidden;
}

/* Slide */

.slide {
  position: relative;
  margin-right: -100%;
  width: 100%;
  height: 100%;
  display: none;
  float: left;
}

.slide:first-child {
  display: block;
}

.slide .slide-image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

/* Album */

.album .album-image {
  position: relative;
  margin-bottom: 10px;
  height: 120px;
  display: block;
}

.album .album-image img {
  width: 100%;
  height: 100%;
}

.album .album-image:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  border: 0 solid #0fab30;
  display: block;
  z-index: 1;
  opacity: 0;
  -webkit-transition: all .15s ease-in-out;
  transition: all .15s ease-in-out;
}

.album .album-image:hover:before {
  border-width: 5px;
  opacity: 1;
}

/* News item */

.news-item .news-item-title a {
  color: inherit;
  text-decoration: none;
}

.news-item .news-item-title a:hover {
  color: #0fab30;
}

.news-item .meta {
  top: -15px;
}

/* Pagination  */

.pagination ul {
  font-size: 0;
  text-align: center;
}

.pagination li {
  padding: 1px;
  display: inline-block;
}

.pagination a {
  height: 30px;
  width: 30px;
  font-size: 11px;
  font-weight: 700;
  color: #696d6e;
  line-height: 30px;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
  border: 1px solid #edf0f1;
  display: block;
}

.pagination a:hover {
  color: #20b8d6;
  border-color: #20b8d6;
}

.pagination li.active a {
  color: #fff;
  border-color: #0fab30;
  background-color: #0fab30;
}

/* Map */

.map {
  width: 100%;
  height: 300px;
}

.map img {
  max-width: none;
  width: auto;
}

/* Route */

.route {
  position: relative;
  z-index: 2;
}

.route .control-label {
  color: #fff;
}

.route .form-control {
  border: none;
}

.route .required {
  background-color: #205f1b;
}

/* Directions panel */

.directions-panel .directions-panel-placeholder {
  line-height: normal;
  display: block;
}

.directions-panel table {
  width: 100%;
}

.directions-panel td {
  padding: 10px;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
  color: #6f6b60;
  border-top: 1px solid #ececec;
  border-bottom: none;
}

.directions-panel .adp-placemark {
  border: none;
  background-color: transparent;
}

.directions-panel .adp-placemark td {
  font-weight: 600;
  color: #6f6b60;
  border: none;
}

.directions-panel .adp-summary {
  font-weight: 600;
  color: #6f6b60;
}

.directions-panel .adp-legal {
  font-size: 11px;
  color: #a3a8a3;
}

.directions-panel .adp-substep .adp-stepicon {
  top: 3px;
}

.directions-panel img {
  max-width: none;
}

/* VisualMedia */

.vm {
  width: 26px;
  height: 26px;
  vertical-align: middle;
  display: inline-block;
}

/* Header */

.header {
  position: relative;
  padding: 10px 0;
  z-index: 2;
}

.header .header-actions {
  margin-top: 10px;
}

/* Footer */

.footer {
  position: relative;
  font-size: 11px;
  text-align: center;
  z-index: 1;
}

/* Small devices (tablets, 768px and up) */

@media (min-width: 768px) {
  /* Content padding */

  .content-padding {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  /* Logo */

  .logo {
    width: 150px;
  }

  /* Menu main */

  .menu .menu-container {
    width: 50%;
  }

  /* Visual */

  .visual {
    height: 300px;
  }

  .visual.visual-small {
    height: 200px;
  }

  /* Map */

  .map {
    height: 350px;
  }

  /* Header */

  .header {
    padding: 15px 0;
  }

  .header .header-actions {
    margin-top: 25px;
  }
}

/* Medium devices (desktops, 992px and up) */

@media (min-width: 992px) {
  /* Heading */

  h1 {
    font-size: 34px;
  }

  /* Logo */

  .logo {
    width: 200px;
  }

  /* Menu main */

  .menu {
    position: relative;
    margin: 40px 0 0 30px;
    width: auto;
    float: left;
  }

  .menu:before {
    display: none;
  }

  .menu .menu-container {
    position: relative;
    margin: 0;
    width: auto;
    background-color: transparent;
    overflow: visible;
  }

  .menu ul {
    margin: 0;
  }

  .menu li {
    border-top: none;
    float: left;
  }

  .menu a {
    padding: 10px 5px;
    font-size: 15px;
    color: #696d6e;
  }

  .menu li.hover > a,
  .menu li:hover > a,
  .menu li.active > a {
    color: #0fab30;
  }

  .menu a button {
    position: relative;
    top: -2px;
    margin: 0 -4px 0 -2px;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }

  .menu ul ul {
    position: absolute;
    top: 100%;
    left: 50%;
    margin: 0 0 0 -1234em;
    padding: 20px 0;
    width: 180px;
    background-color: #0fab30;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    display: block;
    z-index: 999;
  }

  .menu li.hover > ul {
    margin-left: -90px;
  }

  .menu ul ul li {
    border-top: 1px solid #0b9e2a;
    float: none;
  }

  .menu ul ul a {
    padding: 5px 20px;
    font-size: 13px;
    color: #023a0d;
  }

  .menu ul ul a:hover {
    color: #fff;
  }

  .menu ul ul li.hover > a,
  .menu ul ul li.active > a {
    color: #fff;
  }

  .menu ul ul a button {
    top: -3px;
    margin-left: -3px;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  .menu ul ul li.hover > a button,
  .menu ul ul li.active > a button {
    top: -3px;
    margin-left: -3px;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  .menu ul ul ul {
    top: -20px;
    left: 100%;
    background-color: #1bbd3d;
  }

  .menu ul ul li.hover > ul {
    margin-left: 0;
  }

  /* Visual */

  .visual {
    height: 400px;
  }

  .visual.visual-small {
    height: 300px;
  }

  /* Album */

  .album .album-image {
    height: 150px;
  }

  /* Map */

  .map {
    height: 400px;
  }

  /* VisualMedia */

  .vm {
    float: right;
  }

  /* Header */

  .header {
    padding: 20px 0;
  }

  .header .header-actions {
    margin-top: 40px;
  }

  /* Footer */

  .footer {
    text-align: left;
  }
}

.reservering-container .controls {
  float: right;
  background-color: #0196da;
  width: 30px;
  margin: 0 0 0 1px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.reservering-container .controls .control {
  display: block;
  line-height: normal;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.reservering-container .controls .control .icon_control {
  display: block;
  height: 15px;
  width: 30px;
  border-top-right-radius: 2px;
  background: url(/bundles/app/frontend/images/icon_controls.png) top left no-repeat;
  cursor: pointer;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  opacity: 1;
}

/* Large devices (large desktops, 1200px and up) */

body {
  overflow-x: hidden;
}

h1 {
  color: #003067;
  text-align: center;
}

header {
  position: relative;
  height: 200px;
  z-index: 2 !important;
}

header .header-content {
  width: 100%;
  height: 450px;
  text-align: center;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

header .header-content.high {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 200px;
}

header .header-content.high .logo {
  width: 100%;
  height: 200px;
  background-image: url(/assets/images/smitspaviljoen-wit.4598e26756512ccbaab73d60a386fb6d.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 400px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 600px) {
  header .header-content.high .logo {
    background-size: 300px;
  }
}

header .header-content.narrow {
  position: fixed;
  top: 0;
  left: 0;
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
  height: 100px;
  background-color: white;
  z-index: 10;
}

header .header-content.narrow .logo {
  width: 100%;
  height: 90px;
  background-image: url(/assets/images/smitspaviljoen.4e759c26668a73ad67eb0d9f7ed90273.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 200px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 600px) {
  header .header-content.narrow .logo {
    background-size: 200px;
  }
}

.main-visual {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  height: 450px;
  width: 100%;
}

.main-visual .visual-img {
  background-position: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 450px;
}

.main-visual .visual-button {
  display: inline-block;
  position: absolute;
  top: 60%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 2;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
}

.main-visual .visual-button .btn-reserveer {
  display: inline-block;
  font-size: 21px;
  padding: 15px 30px;
  margin: 0;
  height: auto;
  border-radius: 3px;
  color: #fff;
  background: #f76706;
}

.main-visual .visual-button .btn-reserveer:hover {
  color: #fff;
  background: #18adf1;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.25);
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.main-visual .visual-button .btn-reserveer:before {
  border: none !important;
  padding: 0;
  margin: 0;
}

.main-content {
  margin-top: 250px;
}

.reservering-container {
  background: #18acf2 url(/assets/images/reserveer-back.65f9402a7128e798d81aea7c7df48d8e.jpg) no-repeat bottom right;
  margin: 0 auto;
  text-shadow: 1px 1px 2px black;
}

.reservering-content {
  position: relative;
  padding: 30px;
  color: white;
  overflow: hidden;
}

.reservering-content h2 {
  color: white;
}

.reservering-contactdata {
  float: left;
  width: 100%;
}

.reservering-bootdata {
  float: left;
  width: 100%;
}

.reservering-bootdata .form_label {
  width: 50%;
  float: left;
}

@media only screen and (max-width: 600px) {
  .reservering-bootdata .form_label {
    width: 100%;
  }
}

.reservering-bootdata .form-control {
  width: 50%;
  float: left;
  margin-bottom: 10px;
}

@media only screen and (max-width: 600px) {
  .reservering-bootdata .form-control {
    width: 100%;
  }
}

.boot-img {
  background: transparent url(/assets/images/boten-smits-paviljoen-giethoorn.b746f9d2d2b517ea0914fd1517a21017.jpg) no-repeat bottom right;
  min-height: 500px;
}

@media only screen and (max-width: 600px) {
  .boot-img {
    display: none;
  }
}

/* read */

#info a {
  color: #003067;
}

#info a:hover {
  color: #18acf2;
}

#info + [data-readmore-toggle],
#info[data-readmore] {
  display: block;
  width: 100%;
}

#info[data-readmore] {
  -webkit-transition: height 500ms;
  transition: height 500ms;
  overflow: hidden;
}

.btn.btn-primary {
  float: right;
  color: #fff;
  background: #003067;
}

.reservering-contactdata {
  display: none;
}

.reservering-contactdata .btn.btn-primary {
  margin-bottom: 30px;
}

.btn.btn-secondary {
  position: relative;
  width: 100%;
  font-size: 14px;
}

.btn.btn-secondary:before {
  position: absolute;
  border-top: 1px solid #ddd !important;
  content: '';
  height: 40px;
  top: 20px;
  left: 0px;
  right: 0px;
  z-index: 0;
}

.btn.btn-secondary.more {
  background-color: white !important;
  color: #003067;
  display: none;
  z-index: 1;
}

.btn.btn-secondary.more span {
  position: relative;
  background-color: white !important;
  display: inline-block;
  padding: 0 20px;
}

.btn.btn-secondary.less {
  background-color: white !important;
  color: #003067;
  display: none;
  z-index: 1;
}

.btn.btn-secondary.less span {
  position: relative;
  background-color: white !important;
  display: inline-block;
  padding: 0 20px;
}

.reservering_next {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.footer {
  background-color: #364a53;
  position: relative;
  font-size: 11px;
  text-align: center;
  z-index: 1;
}

.footer .footer-data__wrapper {
  width: 100%;
  text-align: center;
}

.footer .footer-data__wrapper a {
  color: white;
}

.footer .footer-data__wrapper .footer-data {
  position: relative;
  display: inline-block;
  text-align: center;
}

.footer .footer-data__wrapper .footer-data:before {
  position: absolute;
  top: 10px;
  left: -120px;
  content: '';
  border-bottom: 1px solid #929697;
  width: 100px;
  display: block;
}

@media only screen and (max-width: 600px) {
  .footer .footer-data__wrapper .footer-data:before {
    display: none;
  }
}

.footer .footer-data__wrapper .footer-data:after {
  position: absolute;
  top: 10px;
  right: -120px;
  content: '';
  border-bottom: 1px solid #929697;
  width: 100px;
  display: block;
}

@media only screen and (max-width: 600px) {
  .footer .footer-data__wrapper .footer-data:after {
    display: none;
  }
}

.footer .menu-footer {
  position: relative;
  top: -1px;
  margin: 20px;
  vertical-align: middle;
  display: inline-block;
}

.footer .menu-footer ul li a {
  color: white;
}

.footer .text-light {
  color: #929697 !important;
}

.footer-logo {
  width: 100%;
  text-align: center;
}

.footer-logo .vm {
  float: none !important;
}

.footer-logo .vm img {
  width: 26px;
  height: 26px;
  vertical-align: middle;
  display: inline-block;
}